import React, {useState} from "react";
import image from '../images/Inveskal_image.png'
import  '../i18n'
import {useTranslation} from 'react-i18next'

const InvestKal = () => {

    const {t} = useTranslation()

    return (
        <div className="invest-kal" style={{paddingLeft: '10%', paddingRight: '10%'}}>
            <p>
                {t("inveskal.text")}
            </p>
            <a href="https://tool.altiainvestment.com/">{t("inveskal.link")}</a>

            <img src={image} style={{maxWidth: '100%'}}/>

        </div>
    )


}

export default InvestKal