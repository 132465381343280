import React, {useState} from "react";
import '../style/Team.css'
import david from "../images/team_david.png"
import frank from "../images/team_frank.jpg"
import jean from "../images/team_jean.jpeg"
import betsalel from "../images/team_betsalel.png"
import smalldavid from "../images/david.png"
import smallfrank from "../images/frank.png"
import smalljeen from "../images/jean.jpg"
import smallbetsalel from "../images/betsalel.jpg"

import '../i18n'
import {useTranslation} from 'react-i18next'

const NewTeam = () => {

    const {t} = useTranslation()

    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let style = {paddingLeft:"10%", width: '90%'}
    if (isSafari)
        style['height'] = '90%'

    return (
        <>
            {/*  This is a Team only for lg+ screens*/}
            <div className="d-none d-lg-block">
                <div className="team">

                    {/*  section de franck */}
                    <div className="row">
                        <div className="team-text col">
                            <h2 className="title">{t("team.frank.title")}</h2>
                            <h4 className="secondary-title">{t("team.frank.subtitle")}</h4>
                            <p>
                                {t("team.frank.text")}
                            </p>
                        </div>
                        <div className="col">
                            <img src={frank} />
                        </div>
                    </div>

                    {/*  section de David */}
                    <div className="row">
                        <div className="col" style={{ paddingLeft: '10%' }}>
                            <img src={david} />
                        </div>
                        <div className="team-text col">
                            <h2 className="title">{t("team.david.title")}</h2>
                            <h4 className="secondary-title">{t("team.david.subtitle")}</h4>
                            <p>
                                {t("team.david.text")}
                            </p>
                        </div>
                    </div>

                    {/*  section de Jch */}
                    <div className="row">
                        <div className="team-text col">
                            <h2 className="title">{t("team.jean.title")}</h2>
                            <h4 className="secondary-title">{t("team.jean.subtitle")}</h4>
                            <p>
                                {t("team.jean.text")}
                            </p>
                        </div>
                        <div className="col">
                            <img src={jean} />
                        </div>
                    </div>

                    {/*  section de betsalel */}
                    <div className="row">
                        <div className="col" style={{ paddingLeft: '10%' }}>
                            <img src={betsalel} />
                        </div>
                        <div className="team-text col">
                            <h2 className="title">{t("team.betsalel.title")}</h2>
                            <h4 className="secondary-title">{t("team.betsalel.subtitle")}</h4>
                            <p>
                                {t("team.betsalel.text")}
                            </p>
                        </div>
                    </div>

                    
                </div>
            </div>



            {/*  This is a Team only for lg- screens*/}
            <div className="d-lg-none">

                <div className="team-mobile">
                    <img style={style} src={smallfrank} />
                    <div className="row">
                        <div className="team-text">
                            <h2 className="title">{t("team.frank.title")}</h2>
                            <h4 className="secondary-title">{t("team.frank.subtitle")}</h4>
                            <p>
                                {t("team.frank.text")}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <img style={style} src={smalldavid} />
                        <div className="team-text">
                            <h2 className="title">{t("team.david.title")}</h2>
                            <h4 className="secondary-title">{t("team.david.subtitle")}</h4>
                            <p>
                                {t("team.david.text")}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <img style={style} src={smalljeen} />
                        <div className="team-text">
                            <h2 className="title">{t("team.jean.title")}</h2>
                            <h4 className="secondary-title">{t("team.jean.subtitle")}</h4>
                            <p>
                                {t("team.jean.text")}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <img style={style} src={smallbetsalel} />
                        <div className="team-text">
                            <h2 className="title">{t("team.betsalel.title")}</h2>
                            <h4 className="secondary-title">{t("team.betsalel.subtitle")}</h4>
                            <p>
                                {t("team.betsalel.text")}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}


export default NewTeam;