import React, {useState} from "react";
import '../style/Team.css'
import Card from "react-bootstrap/Card";
import frank from "../images/frank.png"
import david from "../images/david.png"
import jean from "../images/jean.jpg"
import betsalel from "../images/betsalel.jpg"

const Team = () => {


    return (
        <>
            {/*  This is a Team only for lg+ screens*/}
            <div className="d-none d-lg-block">
        <div className="team-block">
            <h1 className="title"> Notre équipe </h1>
            <div className="row">
                <div className="col card-wrapper bg-custom1">
                    <Card bg='custom1' text="white">
                        <Card.Img variant="top" src={frank}/>
                        <Card.Body>
                            <Card.Title>FRANCK SALA</Card.Title>
                            <div className="text1">
                            <Card.Text>
                            Franck Sala, ingénieur des Mines, a acquis 20 ans d’expérience dans le domaine des
                                microprocesseurs en Israël au sein d’une société leader mondial du secteur. Après avoir
                                occupé
                                différents postes de développeur puis dirigé une équipe de designers, Franck a rejoint
                                le
                                département d’architecture au sein duquel il définit les algorithmes des futures
                                générations de
                                processeurs. Il y a notamment acquis une expertise unique des algorithmes de prédiction
                                de
                                branches qu’il a entrepris d’adapter à la finance. Passionné, Franck enseigne aussi la
                                micro
                                architecture au Technion, Israël.
                            </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col card-wrapper bg-custom2">

                    <Card bg='custom2' text="white">
                        <Card.Img variant="top" src={betsalel}/>
                        <Card.Body>
                            <Card.Title>BETSALEL ELFASSY</Card.Title>
                            <Card.Text>
                            Betsalel Elfassy est diplômé en comptabilité du CNAM - Intec et en finance de marché de la Cass Business School.
                            Il a travaillé dans plusieurs FO à Londres, a acquis une expérience dans le trading algorithmique et s'est spécialisé dans le HFT (High Frequency Trading).

                            Début 2021, il a fondé Bull Run Consulting, une société de conseil en technologie blockchain et conseille plusieurs Family Offices dans leurs discussions sur les actifs cryptographiques.

                            Betsalel est un expert en cryptographie et travaille au développement de cette industrie au sein d'Altia.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>


                <div className="col card-wrapper bg-custom2">

                    <Card bg='custom1' text="white">
                        <Card.Img variant="top" src={david}/>
                        <Card.Body>
                            <Card.Title>DAVID SALA</Card.Title>
                            <Card.Text>
                            David SALA est titulaire d’un DESS de Finance obtenu à l’université de Nice Sophia
                                Antipolis. Il
                                compte 19 ans d’expérience sur les marchés financiers. Après avoir exercé le métier de
                                gérant
                                actions, il entreprend une carrière de vendeur « actions » auprès d’une clientèle
                                institutionnelle au sein des plus prestigieuses sociétés de bourse parisiennes. Entre
                                2007 et
                                2014, il se verra attribuer la codirection du desk Action de la filiale parisienne de
                                Cantor
                                Fitzgerald EuropeCard.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col card-wrapper bg-custom1">

                    <Card bg='custom2' text="white">
                        <Card.Img variant="top" src={jean}/>
                        <Card.Body>
                            <Card.Title>JEAN-CLAUDE HIREL</Card.Title>
                            <Card.Text>
                                Jean Claude Hirel est ingénieur de l’Ecole Centrale de Paris et master of Nuclear
                                Sciences de
                                l’université de Stanford. Après avoir intégré l’Inspection Générale des Finances, il
                                prendra la
                                Présidence de différents Groupes industriels de taille mondiale. Au cours de ses longues
                                années
                                de carrière il a acquis une compétence particulière dans le développement de programmes
                                de
                                modélisation des données rattachées à l’entreprise.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
            </div>

            {/*  This is a Team only for lg- screens*/}
            <div className="d-lg-none">
            </div>
            </>
    )
}


export default Team;