import React, {Suspense, useEffect, useState} from "react";
import '../style/News.css'
import '../i18n'
import {useTranslation} from 'react-i18next'
import Loader from "./Loader";

const News = () => {
    const [articles, setArticles] = useState([])
    const {t} = useTranslation()

    const [done, setDone] = useState(false)


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
        };
        fetch("https://admin.altiainvestment.com/website/get_articles/", requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                let temp = []
                res.map((article) => {
                    temp.push({
                        "id": article.id,
                        "title_en": article.title_en,
                        "subtitle_en": article.subtitle_en,
                        "text_en": article.text_en,
                        "title_fr": article.title_fr,
                        "subtitle_fr": article.subtitle_fr,
                        "text_fr": article.text_fr,
                        "image": "data:image/png;base64," + article.image,
                        "video_url": article.video_url
                    })
                })
                setArticles(temp.reverse())
                console.log(articles)
            })
            .then(() => setDone(true))

    }, [])

    let lang = '_en'
    if (localStorage.getItem('current') === 'fr')
        lang = '_fr'

    //handling image problem in safari
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let style = {}
    if (isSafari)
        style['height'] = '100%'

    console.log(articles)

    const articleBox = (article) => {
        return (
            <div>
                <div className="d-none d-lg-block article-box-wrapper">
                    <div className="article-box article-box-big">
                        <img src={article['image']} style={style}/>
                        <div>
                            <h4>{article['title' + lang]}</h4>
                            <h6>{article['subtitle' + lang]}</h6>
                        </div>

                        <button onClick={() => {
                            openArticle(article)
                        }}>{t("news.button")}
                        </button>
                    </div>
                </div>
                <div className="d-lg-none">
                    <div className="article-box article-box-small">
                        <div>
                            <img src={article['image']} style={style} className="small-img"/>
                            <h4>{article['title'+ lang]}</h4>
                            <h6>{article['subtitle' + lang]}</h6>
                            <div className="button-wrapper">
                                <button onClick={() => {
                                    openArticle(article)
                                }}>{t("news.button")}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {!done ? <Loader news={true}/> :
                <div className="News-Wrapper">
                    {articles[0] ? articles.map((article) => articleBox(article)) : null}
                </div>
            }
            </>
    )
}

function openArticle (article){
    if (article['video_url'])
        window.open(article['video_url'])
    else
        window.open('/article/' + article['id'], '_self')
}



export default News;