import React, {useState} from "react";
import '../style/TitleImage.css'
import image from '../images/TitleImage.jpeg'

const TitleImage = ({text}) => {

    return (
        <div className="title-image">
            <div className="containerBox">
                <div className="text-box">
                    <h4>{text}</h4>
                </div>
                <img className="img-responsive" src={image}/>
            </div>
        </div>
    )
}

export default TitleImage;