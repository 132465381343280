import React, {useRef, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import '../style/NavBar.css'
import  '../i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from 'react-i18next'
import LanguageSelector from "./LanguageSelector";

const NavBar = () => {
    const {t} = useTranslation()

    const openNav = (e) => {
        e.currentTarget.style.width = '812px'
        e.currentTarget.children[1].style.visibility = 'hidden'
    }

    const closeNav = (e) => {
        e.currentTarget.style.width = '112px'
        e.currentTarget.children[1].style.visibility = 'visible'

    }

    let [textHome, setTextHome] = useState(false)
    let [textPresentation, setTextPresentation] = useState(false)

    return (
        <div className="nav-bar">

            {/*  This is a Navbar only for lg+ screens*/}
            <div className="d-none d-lg-block">
                <div onMouseEnter={openNav}
                     onMouseLeave={closeNav}
                     id="mySidenav" className="sidenav">
                    <ul>
                        <li>
                            <a href="/"
                               onMouseEnter={() => setTextHome(true)}
                               onMouseLeave={() => setTextHome(false)}
                            >
                                {t("navBar.home")}
                                {textHome ?
                                    <p className="nav-help-text">{t("navBar.home.sub-title")}</p>
                                    : null}
                            </a>

                        </li>
                        <li>
                            <a href='/presentation'
                               onMouseEnter={() => setTextPresentation(true)}
                               onMouseLeave={() => setTextPresentation(false)}
                            >
                                {t("navBar.presentation")}
                                {textPresentation ?
                                    <p className="nav-help-text">{t("navBar.presentation.sub-title")}</p>
                                    : null}
                            </a>

                        </li>

                        <li>
                            <a href="/CopyPortfolio"> {t("navBar.copyPortfolio")} </a>
                        </li>

                        <li>
                            <a href="/InvesKal"> {t("navBar.inveskal")} </a>
                        </li>

                        <li>
                            <a href="/news"> {t("navBar.news")} </a>
                        </li>
                        <li>
                            <a href="/contact"> {t("navBar.contact")} </a>
                        </li>
                        <li>
                            <LanguageSelector id="language-btn"/>
                        </li>
                    </ul>
                    <FontAwesomeIcon className="nav-bar-icon" icon={faBars}/>

                </div>
            </div>


            {/*  This is a Navbar only for lg- screens*/}
            <div className="d-lg-none">

                <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand href="/">Altia-Investment</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">{t("navBar.home")}</Nav.Link>
                            <Nav.Link href="/presentation">{t("navBar.presentation")}</Nav.Link>
                            <Nav.Link href="/CopyPortfolio">{t("navBar.copyPortfolio")}</Nav.Link>
                            <Nav.Link href="/InvesKal">{t("navBar.inveskal")}</Nav.Link>
                            <Nav.Link href="/news">{t("navBar.news")}</Nav.Link>
                            <Nav.Link href="/contact">{t("navBar.contact")}</Nav.Link>
                            <LanguageSelector id="language-btn-mobile"/>
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>
            </div>

        </div>
    )
}

export default NavBar;