import React, {useState} from "react";
import '../i18n'
import '../style/KeyAssets.css'
import {useTranslation} from 'react-i18next'


const KeyAssets = () => {
    const {t} = useTranslation()
    const [hover, setHover] = useState(false)
    const [className, setClassName] = useState("")
    const animateEnter = (e) => {
        let cardImage = e.currentTarget.children[0].children[0]
        setClassName(cardImage.className)
        cardImage.className = ""
        let cardText = e.currentTarget.children[0].children[1].children[1]
        cardText.style.visibility = 'visible'
    }
    const animateExit = (e) => {
        let cardImage = e.currentTarget.children[0].children[0]
        let cardText = e.currentTarget.children[0].children[1].children[1]
        cardText.style.visibility = 'hidden'
        cardImage.className = className
    }


    return (
        <div>
            <div className="d-none d-lg-block">
                <ul className="cards">
                    <li className="cards__item" onMouseEnter={e => animateEnter(e)} onMouseLeave={e => animateExit(e)}>
                        <div className="card">
                            <div className="card__image card__image--one"></div>
                            <div className="card__content">
                                <div className="card__title">{t("keyAssets.firstTitle")}</div>
                                <p className="card__text" style={{marginTop: '30px'}}>{t("keyAssets.firstText")} </p>
                            </div>
                        </div>
                    </li>
                    <li className="cards__item" onMouseEnter={e => animateEnter(e)} onMouseLeave={e => animateExit(e)}>
                        <div className="card">
                            <div className="card__image card__image--two"></div>
                            <div className="card__content">
                                <div className="card__title">{t("keyAssets.secondTitle")}</div>
                                <p className="card__text" style={{marginTop: '30px'}}>{t("keyAssets.secondText")}.</p>
                            </div>
                        </div>
                    </li>
                    <li className="cards__item" onMouseEnter={e => animateEnter(e)} onMouseLeave={e => animateExit(e)}>
                        <div className="card">
                            <div className="card__image card__image--three"></div>
                            <div className="card__content">
                                <div className="card__title">{t("keyAssets.thirdTitle")}</div>
                                <p className="card__text" style={{marginTop: '30px'}}>{t("keyAssets.thirdText")}</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="d-lg-none">
                <ul className="cards">
                    <li className="cards__item">
                        <div className="card">
                            <div className="card__image mobile-card card__image--one-mobile"><p>{t("keyAssets.firstText")}</p></div>
                            <div className="card__content">
                                <div className="card__title">{t("keyAssets.firstTitle")}</div>
                            </div>
                        </div>
                    </li>
                    <li className="cards__item">
                        <div className="card">
                            <div className="card__image mobile-card card__image--two-mobile"><p>{t("keyAssets.secondText")}</p></div>
                            <div className="card__content">
                                <div className="card__title">{t("keyAssets.secondTitle")}</div>
                            </div>
                        </div>
                    </li>
                    <li className="cards__item">
                        <div className="card">
                            <div className="card__image mobile-card card__image--three-mobile"><p>{t("keyAssets.thirdText")}</p></div>
                            <div className="card__content">
                                <div className="card__title">{t("keyAssets.thirdTitle")}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default KeyAssets;