import React, {useEffect, useState} from "react";
import '../style/Article.css'
import Video from "./Video";
import {useParams} from "react-router";
import '../i18n'
import {useTranslation} from 'react-i18next'


// this is an article
const Article = () => {

    const [article, setArticle] = useState({})
    let {title} = useParams();

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
        };
        fetch("https://admin.altiainvestment.com/website/get_article/" + title + '/', requestOptions)
            .then(res => res.json())
            .then(res => {
                res['image'] = "data:image/png;base64," + res['image']
                setArticle(res)
                console.log(article)
            })

    }, [])

    let lang = '_en'
    if (localStorage.getItem('current') === 'fr')
        lang = '_fr'

    console.log(article)

    return (
        <div>
            {
                article ?
                    <div className="article">

                        <img src={article['image']}/>
                        <h1 className="title">{article['title' + lang]}</h1>
                        <h3 className="secondary-title">{article['subtitle' + lang]}</h3>
                        <p dangerouslySetInnerHTML={{__html: article['text' + lang]}}></p>

                        <Video url={article['video_url']}/>
                    </div>

                    : <div/>
            }
        </div>
    )

}


export default Article