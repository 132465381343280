import React, {useEffect, useRef} from "react";
import Landing from "../components/Landing";
import NavBar from "../components/NavBar";
import Example4 from "../components/OpeningText";
import PresentationText from "../components/PresentationText";
import CarouselPic from "../components/CarouselPic";
import Title from "../components/Title";
import  '../i18n'
import {useTranslation} from 'react-i18next'
import Footer from "../components/Footer";

const Presentation = () => {

    const {t} = useTranslation()

    return (
        <>
            <NavBar/>
            <div className="content">
                <div>
                    <div className="d-none d-lg-block">
                    <div style={{width: '90%',paddingLeft:'10%', marginBottom: '5%'}}>
                        <CarouselPic/>
                    </div>
                    </div>
                    <div className="d-lg-none">
                        <div style={{width: '90%',paddingLeft:'10%', marginBottom: '15%',marginTop:"10%"}}>
                            <CarouselPic/>
                        </div>
                    </div>
                    <Title title={t("presentation.title")}/>
                   <PresentationText/>
                    <Footer/>

                </div>
            </div>
        </>

    )
}

export default Presentation;