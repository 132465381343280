import React, {useState} from "react";
import  '../i18n'
import {useTranslation} from 'react-i18next'


const History = () => {
    const {t} = useTranslation()

    return (
        <div style={{marginTop:"5%"}}>
            <p className="text">
                {t("history.text")}
            </p>
        </div>
    )
}


export default History