import React, {useEffect, useRef} from "react";
import NavBar from "../components/NavBar";
import TitleImage from "../components/TitleImage";
import InvestKal from "../components/InvestKal";
import Title from "../components/Title";
import  '../i18n'
import {useTranslation} from 'react-i18next'
import Footer from "../components/Footer";

const InvestKalPage = () => {
    const {t} = useTranslation()

    return (
        <>
            <NavBar/>
            <div className="content">
                <div>
                    <TitleImage text={t("titleImage.inveskal")}/>
                    <Title title={t("inveskal.title")}/>
                    <InvestKal/>
                </div>
                <Footer/>
            </div>
        </>

    )
}

export default InvestKalPage;