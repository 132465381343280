import React, {useEffect, useRef} from "react";
import Landing from "../components/Landing";
import NavBar from "../components/NavBar";
import NewTeam from "../components/NewTeam";
import OpeningText from "../components/OpeningText";
import LabelFintec from "../components/LabelFintec";
import Title from "../components/Title";
import KeyAssets from "../components/KeyAssets";
import Vision from "../components/Vision";
import Footer from "../components/Footer";
import  '../i18n'
import {useTranslation} from 'react-i18next'


const Main = () => {

    const {t} = useTranslation()


    const intro = useRef(null);
    useEffect(() => {
        setTimeout(() => {
           scroll()
        }, 2000);
    }, [])

    async function scroll() {

        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (!isSafari)
            window.scrollTo({behavior: 'smooth', top: intro.current.offsetTop * 0.65})
    }

    return (
        <>
            <NavBar/>
            <div className="content">
                <Landing/>
                <div
                    ref={intro}>

                    <OpeningText/>
                    <Title title={t("keyAssets.title")} align="middle"/>
                    <KeyAssets/>
                    <Title title={t("team.title")} align="middle"/>
                    <NewTeam/>
                    <Title title={t("more.title")} align="middle"/>
                    <LabelFintec/>
                    <Title title={t("vision.title")} align="middle"/>
                    <Vision/>
                </div>
                <Footer/>
            </div>
        </>

    )
}

export default Main;