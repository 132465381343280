import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const LanguageSelector = ({id}) => {
    const {i18n} = useTranslation()

    useEffect(() => {
            let current = localStorage.getItem("current")
            current ? i18n.changeLanguage(current) : i18n.changeLanguage("en")
            let token = current === "fr" ? 'french-flag' : 'american-flag'
            let other = current === "fr" ? 'american-flag' : 'french-flag'

            let element = document.getElementById(id).classList
            element.add(token)
            element.remove(other)
        }
        , [])

    const lang = {
        "fr": "en",
        "en": "fr"
    }

    const toggleFlags = () => {
        document.getElementById(id).classList.toggle('american-flag')
        document.getElementById(id).classList.toggle('french-flag')

    }

    return (

        <div className="language-btn" id={id}
             onClick={() => {
                 localStorage.setItem("current", lang[i18n["language"]])
                 i18n.changeLanguage(lang[i18n["language"]])
                 toggleFlags()
             }
             }
        />


    )
}

export default LanguageSelector