import React from "react";
import logo from '../images/Altia_logo.jpg'
import "../style/OpeningText.css"
import image1 from '../images/opening_altia.jpg'
import image2 from '../images/opening_team.jpg'
import  '../i18n'
import {useTranslation} from 'react-i18next'
import LanguageSelector from "./LanguageSelector";


const OpeningText = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="opening">

                <div className="row">
                    <div className="col-md-6 opening-image">
                        <img src={image1}
                             className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-6">
                        <h4 className="title">{t("opening.title1")}</h4>
                        <h4 className="subheading secondary-title">
                            {t("opening.subtitle1")}
                        </h4>
                        <p className="text-muted">
                            {t("opening.text1")}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="title">{t("opening.title2")}</h4>
                        <h4 className="subheading secondary-title">{t("opening.subtitle2")} </h4>
                        <p className="text-muted">
                            {t("opening.text2")}
                        </p>
                    </div>
                    <div className="col-md-6 opening-image">
                        <img src={image2}
                             className="img-fluid" alt=""/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OpeningText;