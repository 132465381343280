import React, {useEffect, useState} from "react";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import en1 from '../carousel_img/carousel_en_credibility.jpg'
import en2 from '../carousel_img/carousel_en_AI.jpg'
import en3 from '../carousel_img/carousel_en_fintec.jpg'

import fr1 from '../carousel_img/carousel_fr_credibility.jpg'
import fr2 from '../carousel_img/carousel_fr_AI.jpg'
import fr3 from '../carousel_img/carousel_fr_fintec.jpg'

const CarouselPic = () => {

    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const [images, setImages] = useState([])

    useEffect(() => {
        if (localStorage.getItem('current') === 'fr')
            setImages([fr1, fr2, fr3])
        else
            setImages([en1, en2, en3])

    }, [localStorage.getItem('current')])

    const properties = [
        {
            name: 'General',
            props: [
                {
                    name: 'slider-height-percentage',
                    type: 'range',
                    max: 100,
                    min: 10,
                    suffix: '%',
                }
            ]
        }
    ]

    return (
        <div className="carousel">
            <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={6000}
                media={[
                    {
                        source: images[0],

                    },
                    {
                        source: images[1],
                    },
                    {
                        source: images[2],
                    },
                ]}
            />
        </div>
    )
}

export default CarouselPic