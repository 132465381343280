import React, {useState} from "react";
import '../style/Contact.css'
import {Form, Button, Alert} from 'react-bootstrap'
import  '../i18n'
import {useTranslation} from 'react-i18next'

const ContactForm = () => {
    const {t} = useTranslation()


    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [result, setResult] = useState("")

    function sendToAPi(name, email, subject, message) {

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify([{name, email, subject, message}])
        };
        fetch('https://admin.altiainvestment.com/website/send_mail/', requestOptions)
            .then(response => response.json())
            .then(data => setResult(data['success']))
            .catch(err => setResult(err['error']))
    }

    return (
        <div className="contact">

            {result ? <Alert variant="success">{result}</Alert> : null}

            <div className="row">
                <div className="col-lg">
                    <Form>
                        <Form.Group>
                            <Form.Label> {t("contact.label.name")} </Form.Label>
                            <Form.Control placeholder="Enter your name"
                                          onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label> {t("contact.label.email")} </Form.Label>
                            <Form.Control placeholder="Enter your email"
                                          onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                {t("contact.sub-label.email")}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label> {t("contact.label.subject")} </Form.Label>
                            <Form.Control placeholder="Enter the subject"
                                          onChange={(e) => setSubject(e.target.value)}
                            />

                        </Form.Group>

                        <Form.Group>
                            <Form.Label> {t("contact.label.message")} </Form.Label>
                            <Form.Control placeholder="Enter your message" as="textarea"
                                          onChange={(e) => setMessage(e.target.value)}
                            />

                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={(e) => {
                            sendToAPi(name, email, subject, message)
                        }}>
                            Submit
                        </Button>
                    </Form>
                </div>
                <div className="col-lg">
                    <div className="contact-block">
                        <div className="title-wrapper">

                            <h4> David Sala </h4>
                            <h4> 9 Avenue Franklin Roosevelt, Paris 75008</h4>
                            <h4> 01 45 64 67 67</h4>
                            <h4> +33 (0) 6 27 33 32 46 </h4>
                            <h4>david.sala@altia-investment.com </h4>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    )
}

export default ContactForm;