import React from "react";
import '../style/CopyPortfolio.css'
import image from '../images/portfolio.png'
import Title from "./Title";
import '../i18n'
import {Trans, useTranslation} from 'react-i18next'

const CopyPortfolio = () => {

    const {t} = useTranslation()
    return (
        <div className="CopyPortfolio">


            <Title title={t("copyPortfolio.title1")}/>
            <p className="text" dangerouslySetInnerHTML={{__html: t("copyPortfolio.text1")}}></p>

            <img src={image}/>

            {/*<Title title={t("copyPortfolio.title2")}/>*/}

            <p className="text">
                {t("copyPortfolio.text2")}
            </p>


        </div>
    )
}


export default CopyPortfolio