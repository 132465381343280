import React, {useEffect, useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from "./pages/Main";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Presentation from "./pages/Presentation";
import CopyPortfolio from "./pages/CopyPortfolio";
import Contact from "./pages/Contact";
import NewsPage from "./pages/News";
import Team from "./components/Team";
import ArticlePage from "./pages/Article";
import HistoryPage from "./pages/History";
import InvestKalPage from "./pages/InvestKal";
import AboutUsVideo from "./pages/AboutUsVideo";
import Loader from "./components/Loader";
import  './i18n'
import {useTranslation} from 'react-i18next'

// Carousel list: https://github.com/rcaferati/react-awesome-slider

//TODO finish squares
//TODO news page + connection to database
//TODO add word explanation in Presentation

//TODO add dual language

function App() {

    const {t} = useTranslation()
    const [done, setDone] = useState(false)
    const [trigger, setTrigger] = useState(false)
    useEffect(() => {
        if (t('finished') === 'done'){
            setDone(true)
        }

    }, [trigger])

    useEffect(() => {
        if (t('finished') === 'done'){
            setTrigger(!trigger)
        }
    }, [t('finished')])

    return (
        <>
        {!done ? <Loader news={false}/> :
        <Router>
            <Switch>
                <Route path="/team">
                    <Team/>
                </Route>

                <Route path="/presentation">
                    <Presentation/>
                </Route>

                <Route path="/CopyPortfolio">
                    <CopyPortfolio/>
                </Route>

                <Route path="/InvesKal">
                    <InvestKalPage/>
                </Route>

                <Route path="/news">
                    <NewsPage/>
                </Route>

                <Route path="/contact">
                    <Contact/>
                </Route>

                <Route path="/history">
                    <HistoryPage/>
                </Route>

                <Route path='/altia-video'>
                    <AboutUsVideo/>
                </Route>

                <Route path="/article/:title">
                    <ArticlePage/>
                </Route>

                <Route path="/">
                    <Main/>
                </Route>

            </Switch>
        </Router>}
        </>
    );
}

export default App;
