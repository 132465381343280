import React, {useState} from "react";
import '../style/Presentation.css'
import {OverlayTrigger} from "react-bootstrap";
import {Tooltip} from "react-bootstrap";
import '../i18n'
import {Trans, useTranslation} from 'react-i18next'


const PresentationText = () => {
    const {t} = useTranslation()

    const [text, setText] = useState('')

    const text1 = <div className="text-divided">

        <p dangerouslySetInnerHTML={{__html: t('presentation.text1')}}></p>

    </div>
    const text2 =
        <div className="text-divided">
            <p dangerouslySetInnerHTML={{__html: t('presentation.text2')}}></p>
        </div>

    const renderTooltip = (props) => (
        <Tooltip id="tooltip-top" {...props} className="in">
            {t("presentation.tooltip")}
        </Tooltip>
    );

    return (
        <div className="presentation">

            <p className="text" style={{whiteSpace: 'pre-wrap'}}>

                {t("presentation.text.before")}

                {' '}

                <OverlayTrigger
                    placement="right"
                    delay={{show: 250, hide: 400}}
                    overlay={renderTooltip}

                >

                    <strong variant="success">{t("presentation.tooltip.name")}</strong>

                </OverlayTrigger> {' '}

                {t("presentation.text.after")}

                <br/>
                <br/>
                <a className="history-a" href="/history">{t("presentation.historyLinkText")}</a>
                <br/>
                <br/>

            </p>
            <div className="text-buttons" id="buttons">
                <a id="link1"
                   onClick={() => {
                       setText('text1')
                       setTimeout(() => scroll(), 100)
                       line(1)
                   }}>
                    {t("presentation.button1")}
                </a>

                <a id="link2"
                   onClick={() => {
                       setText('text2')
                       setTimeout(() => scroll(), 100)
                       line(2)
                   }}>
                    {t("presentation.button2")}
                </a>

            </div>

            {text === 'text1' ? text1 : null}
            {text === 'text2' ? text2 : null}
            <br/><br/>

        </div>
    )
}


async function scroll() {
//window.scrollTo({behavior: 'smooth', top: window.scrollY + (window.innerHeight / 2)})
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari)
        document.getElementById('buttons').scrollIntoView({behavior: "smooth", inline: "start"});
}

function line(num) {
    let element = document.getElementById('link' + num)
    let other = document.getElementById('link' + (3 - num))

    element.classList.add('underline')
    if (other.classList.contains('underline'))
        other.classList.remove('underline')
}


export default PresentationText;

