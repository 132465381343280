import React, {useEffect, useRef} from "react";
import NavBar from "../components/NavBar";
import TitleImage from "../components/TitleImage";
import History from "../components/History";
import  '../i18n'
import {useTranslation} from 'react-i18next'
import Footer from "../components/Footer";


const HistoryPage = () => {
    const {t} = useTranslation()

    return (
        <>
            <NavBar/>
            <div className="content">
                <div>
                    <TitleImage text={t("titleImage.history")}/>
                    <History/>
                </div>
                <Footer/>

            </div>
        </>

    )
}

export default HistoryPage;