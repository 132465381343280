import React, {useEffect, useRef} from "react";
import NavBar from "../components/NavBar";
import ContactForm from "../components/Contact";
import TitleImage from "../components/TitleImage";
import  '../i18n'
import {useTranslation} from 'react-i18next'
import Footer from "../components/Footer";

const Contact = () => {
    const {t} = useTranslation()

    return (
        <>
            <NavBar/>
            <div className="content">
                <div>
                    <TitleImage text={t("titleImage.contact")}/>
                    <ContactForm/>
                </div>
                <Footer/>
            </div>
        </>

    )
}

export default Contact;