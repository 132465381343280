import React from "react";
import '../style/Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faAt, faHome } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {

    return (


        <footer className="footer">
            <div className="container ">
                <div className="info">
                    <p className=""><br/>
                        ALTIA Investment <br/>
                        9 Avenue Franklin Roosevelt, Paris 75008<br/><br/>
                        Our Technology For Your Investment
                    </p>
                    <p> <br/>
                        <FontAwesomeIcon icon={faPhone}/>
                        01 45 64 67 67
                        <br/>
                        +33 (0) 6 27 33 32 46

                    </p>

                    <p> <br/>
                        <FontAwesomeIcon icon={faAt}/>
                        david.sala@altia-investment.com
                    </p>


                </div>

                <p className="text-center"> @2024 | ALTIA Investment | License photos 123RF.COM</p>

            </div>
        </footer>
    )
}

export default Footer