import React, {useEffect, useRef} from "react";
import Video from "../components/Video";
import NavBar from "../components/NavBar";
import TitleImage from "../components/TitleImage";
import '../i18n'
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const AboutUsVideo = () => {
    const {t} = useTranslation()

    let vid = {
        en: 'https://www.youtube.com/watch?v=Apj2NKixISk',
        fr: 'https://www.youtube.com/watch?v=0hcfKlt51-4'
    }
//        fr: 'https://www.youtube.com/watch?v=IgtVqaYp9TM'

    return (
        <>
            <NavBar/>
            <div className="content">
                <TitleImage text="Altia Investment en images"/>
                <div className="about-us-video">
                    <Video url={vid[localStorage.getItem('current')]}/>
                </div>
            </div>
        </>

    )
}

export default AboutUsVideo
