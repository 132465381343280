import React, {useEffect, useRef} from "react";
import NavBar from "../components/NavBar";
import Article from "../components/Article";
import Video from "../components/Video";
import Footer from "../components/Footer";

const ArticlePage = () => {
    let article = window.parameters
    return (
        <>
            <NavBar/>
            <div className="content">
                <div>
                    <Article article={article}/>
                </div>
                <Footer/>
            </div>
        </>

    )
}

export default ArticlePage;