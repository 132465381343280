import React, {useEffect, useRef} from "react";
import Landing from "../components/Landing";
import NavBar from "../components/NavBar";
import Example4 from "../components/OpeningText";
import PresentationText from "../components/PresentationText";
import CopyPortfolio from "../components/CopyPortfolio";
import Video from "../components/Video";
import TitleImage from "../components/TitleImage";
import  '../i18n'
import {useTranslation} from 'react-i18next'
import Footer from "../components/Footer";


const CopyPortfolioPage = () => {
    const {t} = useTranslation()

    let vid = {
        en: 'https://www.youtube.com/watch?v=F4i-4-1PYA4',
        fr: 'https://www.youtube.com/watch?v=4c2TdmJlGc0'
    }

    return (
        <>
            <NavBar/>
            <div className="content">
                <div>
                    <TitleImage text={t("titleImage.CopyPortfolio")}/>
                    <CopyPortfolio/>
                    {/*<Video url={vid[localStorage.getItem('current')]}/>*/}
                </div>
                <Footer/>

            </div>
        </>
    )
}

export default CopyPortfolioPage;