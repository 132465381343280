import React, {useEffect, useRef} from "react";
import NavBar from "../components/NavBar";
import News from '../components/News'
import TitleImage from "../components/TitleImage";
import '../i18n'
import {useTranslation} from 'react-i18next'
import Footer from "../components/Footer";

const NewsPage = () => {
    const {t} = useTranslation()

    return (
        <>
            <NavBar/>
            <div className="content">
                <div>
                    <TitleImage text={t("titleImage.news")}/>
                    <News/>
                </div>
                <Footer/>
            </div>
        </>

    )
}

export default NewsPage;