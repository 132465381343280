import React, {useRef, useState} from "react";
import '../style/Title.css'

const Title = ({title, align}) => {

    const middle =
        <div className="title-box middle">
            <h1>{title}</h1>
        </div>

    const left =
        <div className="title-box not-middle">
            <h1>{title}</h1>
        </div>

    return (
        <>
            {align === 'middle' ? middle : left}
        </>
    )
}

export default Title