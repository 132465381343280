import React, {useEffect, useState} from 'react';
import LoaderComponent from 'react-loader-spinner'
import '../index.css'
const Loader = ({news}) => {

    return (



        <div style={{display:"flex", height: '100vh', placeContent: !news ? "center" : '', flexDirection: "column", textAlign:"center"}}>
            <h1 style={{color: "#2e3e50"}} className="loader-font">Altia Investment</h1>
            <LoaderComponent
                type="Audio"
                color="#00BFFF"
                height={250}
                width={250}
                timeout={300000}
            />
        </div>
    )
}


export default Loader