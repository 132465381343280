import React, {useEffect} from 'react'
import '../style/Landing.css'
import image from '../images/Altia_logo2.png'

const Landing = () => {

    function checkMobile() {
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (getWidth() < 1100 && isSafari) {
            document.getElementById('wallpaper-id').style.backgroundAttachment = 'revert'
        }
        console.log(getWidth(), isSafari)
    }

    return (

        <div className="landing" onLoad={() =>checkMobile()}>
            <div className="logo-wrapper">
                <img src={image}/>
            </div>

            <div className="header" id="wallpaper-id">
            </div>

        </div>
    )
}

function getWidth() {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
}

export default Landing;