import React from "react";
import ReactPlayer from 'react-player'

const Video = ({url}) => {

    let videoLeft = {
        paddingLeft: '10%'
    }


    return (

    <ReactPlayer
        className='react-player'
        controls={true}
        width='75%'
        height='75%'
        url={url}
        style={videoLeft}
    />

)

}
export default Video