import React, {useState} from "react";
import '../i18n'
import {useTranslation} from 'react-i18next'

const Vision = () => {

    const {t} = useTranslation()

    const style = {
        paddingLeft: '10%',
        paddingRight: '10%',
    }

    return (
        <div>
            <p style={style}>
                {t("vision.text")}

            </p>
        </div>
    )
}

export default Vision