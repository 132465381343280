import React, {useState, useEffect} from "react";
import '../style/LabelFintec.css'
import '../i18n'
import {Trans, useTranslation} from 'react-i18next'


const LabelFintec = () => {
    const {t} = useTranslation()
    const change = () => {
        setTimeout(change1, 3500)
        setTimeout(change2, 5250)
    }

    let size = getWidth() / 7.5

    useEffect(() => change(), [])

    return (
        <div className="label-fintec">
            <div className="d-none d-lg-block">
                <div className="row ">
                    <div className="col" style={{paddingTop: '5%'}}>
                        <div className="square-cont">

                            <div id="square1" className="square square-img-1"
                                 style={{width: size, height: size, marginTop: '0'}}></div>

                            <div className="square square-2" style={{width: size, height: size, marginTop: '0'}}>
                                <p>{t("more.labelFintec.title")}</p>
                            </div>
                        </div>
                        <div className="square-cont">
                            <div className="square square-2 video-square" style={{width: size, height: size}}>
                                <p><a className="img-link" href='/altia-video'>{t("more.videoLinkText")}</a></p>
                            </div>

                            <div id="square2" className="square square-img-2" style={{width: size, height: size}}></div>

                        </div>
                    </div>

                    <div className="col label-fintec-text">
                        <h2 className="title">{t("more.labelFintec.title")}</h2>
                        <h4 className="secondary-title">{t("more.labelFintec.subtitle")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("more.labelFintec.text")}}></p>
                        {/*<p className="">
                        {t("more.labelFintec.text", {link, interpolation: {escapeValue: false}})}
                    </p>*/}
                    </div>
                </div>
            </div>
            <div className="d-lg-none">
                <div className="col label-fintec-text">
                    <h2 className="title">{t("more.labelFintec.title")}</h2>
                    <h4 className="secondary-title">{t("more.labelFintec.subtitle")}</h4>
                    <p dangerouslySetInnerHTML={{__html: t("more.labelFintec.text")}}></p>
                    {/*<p className="">
                        {t("more.labelFintec.text", {link, interpolation: {escapeValue: false}})}
                    </p>*/}
                </div>

                <div className="square-cont" style={{paddingLeft: '0', justifyContent: 'center'}}>
                    <div className="square square-2 video-square">
                        <p><a className="img-link" href='/altia-video'>{t("more.videoLinkText")}</a></p>
                    </div>
                </div>

            </div>
        </div>
    )


}


function change1() {

    document.getElementById('square1').classList.toggle('square-img-1')
    document.getElementById('square1').classList.toggle('square-img-2')

    setTimeout(change1, 3500)

}


function change2() {
    document.getElementById('square2').classList.toggle('square-img-1')
    document.getElementById('square2').classList.toggle('square-img-2')

    setTimeout(change2, 3500)

}

function getWidth() {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
}


export default LabelFintec

